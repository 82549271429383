@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes slide-in-left {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes slide-in-right {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  .slide-in-left {
    animation: slide-in-left 1s forwards;
  }
  
  .slide-in-right {
    animation: slide-in-right 1s forwards;
  }

  


  